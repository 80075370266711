
    @import "config.scss";
 
.form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: end;
}
