
    @import "config.scss";
 
.card {
  width: 100%;
  background-color: #b4b4b4;
  height: 200px;
  border-radius: 20px;
  margin-bottom: 35px;
}

.magneticBand {
  background: black;
  width: 100%;
  height: 30px;
  top: 20%;
  position: relative;
}

.codeInfo {
  background: white;
  width: 85%;
  position: relative;
  padding: 5px;
  left: 7%;
  top: 30%;
}

.dots {
  border: solid 1px var(--color-primary);
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 5%;
  top: -5px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up {
    width: 50%;
  }
}
