
    @import "config.scss";
 
.cardContainer {
  overflow-y: scroll;
  gap: 10px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
}
